import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { Title: 'News', url: 'news', icon: 'mail', ID: 0 },
    { Title: 'Sportprogramm', url: 'sports', icon: 'mail', ID: 399 },
    { Title: 'Impressum', url: 'impressum', icon: 'mail', ID: 400 }
    
  ];
  constructor() {
  }

  async ngOnInit() {
    await fetch('https://kb-rommerz.de/cockpit/api/collections/entries/Sites', { method: 'GET'})
      .then(async (data) => {
        let returnval = await data.json();
        //this.newsEntries = returnval.entries;


        let i = 0;
        returnval.entries.forEach((entry) => {
          entry.url = "/folder/" + i;
          this.appPages.push(entry);
          i++;
        });


        console.log(returnval.entries);
        console.log(this.appPages);
      });

      let hobbyParam = {
        Title: 'Hobbyturnier',
        url: 'hobbyturnier',
        icon: 'mail',
        ID: 200
      };

      this.appPages.push(hobbyParam);

      this.appPages.sort(function compareFn(a,b) {
        if(a.ID > b.ID) {
          return 1;
        }
        if(a.ID < b.ID) {
          return -1;
        }
        if(a.ID == b.ID) {
          return 0;
        }
      });
  }

}
